@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');

::-webkit-scrollbar {
  display: none;
}


body{
  overflow-x: hidden;
}

.emailcontact{
  font-family: 'Poppins';
    color: #fff;
    text-align: center;
    font-size: 16px;
    margin-top: 30px;
}


.banner-slider {
  position: relative;
  display: flex;
  align-items: center;
}

.banner-item {
  position: relative;
  width: 100%;
}



.carousel-content {
  position: absolute;
  bottom: 20px; /* Adjust this value as needed */
  left: 20px;   /* Adjust this value as needed */
  color: white;
  z-index: 2;
}

.gradient-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50%;
  background: linear-gradient(to top, rgba(10, 9, 40, 0.788), transparent);
}

.banner-image {
  width: 100%;
  height: auto;
}

.thumbnail-container {
  position: absolute;
  right: 20px;
  top:70%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  z-index: 1;
}

.thumbnail {
  position: relative;
  margin-bottom: 10px;
  cursor: pointer;
  width: 172px;
  height: 82px;
  z-index: 1;
}

.thumbnail-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 2px solid transparent;
  border-radius: 5px;
  transition: border 0.3s ease;
}



.progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  background:  linear-gradient(87deg, rgb(237 194 62) 17%, rgba(198, 147, 32, 1) 76%);
  transition: width 1s linear;
}



header{
background: rgb(238,182,9);
background: linear-gradient(87deg, rgb(237 194 62) 17%, rgba(198, 147, 32, 1) 76%);
z-index:999999999!important;

}

@font-face {
  font-family: oswald;
  src: url(./FONTNEW/FONT/TT\ Firs\ Neue\ Trial\ Medium.ttf);
}

header{
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #fff; /* Adjust background color as needed */
    z-index: 1000; /* Ensure the header is on top of other content */
}
.topdivider{
    height: 85px;
}
.bluespace{
  background-color: #20204F;
  padding-top: 100px;
}
.logo {
  margin: auto;
  display: block;
  width: 100px;
  padding: 5px;
}

.col-c1 {
  width: 13%;
}
.col-c2 {
  width: 87%;
}


.marque {
  line-height: 30px;
}
.nav-item a {
  color: #000;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 15px !important;
  margin-right: 24px;
  margin-top: 3px;
}
.preheader {
  background-color: #20204f;
}

.stadium-caption h4{
    color: #dbac00;
    font-size: 40px;
    font-weight: 600;
    font-family: 'poppins';
}

.stadium-caption{
    padding: 30px;
}
.stadium-caption p{
    color: #fff;
    font-size: 30px;
    font-weight: 600;
    font-family: 'poppins';
}
.sponsorimg{
    width: 80%;
    margin: auto;
    display: block;
    margin: 20px auto;
}
.preheader p {
    margin-top:6px;
  color: #fff;
  margin-bottom: 0px;
  padding: 6px 6px;
  font-family: "Poppins", sans-serif;
  margin-left: 15px;
  font-size: 12px;
  line-height: 14px;
}
.whitebox svg{
  margin-top: -2px;
  margin-left: -26px;
}
.whitebox {
  background-color: #2F2F73;
  margin: 5px 0px;
}


.whitebox i {
  color: #f4c30d;
}




.newcard {
  position: relative;
  width: 100%; /* Adjust width if necessary */
  overflow: hidden;
  transition: all 0.3s ease;
  }

.newcard1 {
  position: relative;
  width: 100%; /* Adjust width if necessary */
  overflow: hidden;
  transition: all 0.3s ease;
}

.new-caption {
  position: relative;
  padding: 10px;
  transition: all 0.3s ease-in-out;
}

.hidden-paragraph {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.newcard:hover .hidden-paragraph {
  max-height: 50px; /* Adjust based on content */
  opacity: 1;
}

.newcard:hover .new-caption {
  transform: translateY(-18px); /* Moves the entire caption up */
  transition: transform 0.5s ease-in-out;
  margin-bottom: 0px;
}




.whitebox p {
  color: #fff;
  font-weight: medium;
  font-size: 12px !important   ;
  font-weight: 400;
  text-align: center;
}
ul {
  margin-bottom: 0px;
}
.socialicone li {
  text-decoration: none;
  list-style-type: none;
  margin-right: 20px;
}
.socialicone i {
  color: #fff;
  list-style-type: none;
}

/* banner */

.image-container {
    width: 70%;
    position: relative; /* Establishes a positioning context for the text */
    display: inline-block; /* Ensures that the text only takes up the space of the image */
    left: 20%;
    margin-top: -20px;
    margin-bottom: 20px;
  }
  
  .image-container img {
    display: block; /* Removes any unwanted space under the image */
    width: 100%; /* Adjust the width as needed */
  }
  
  .text-bottom {
    position: absolute; /* Allows positioning relative to the container */
    bottom: 0; /* Positions the text at the bottom */
    left: 0; /* Aligns the text to the left; adjust if needed */
    width: 100%; /* Makes the text span the width of the container */
    background-color: rgba(0, 0, 0, 0.5); /* Adds a semi-transparent background */
    color: white; /* Sets the text color to white */
    text-align: center; /* Centers the text horizontally */
    padding: 10px; /* Adds some padding around the text */
  }
  .image-container p{
    color: #fff;
    font-family: 'poppins';
    position: relative;
    margin-top: -47px;
    padding-left: 12px;
    text-transform: uppercase!important;
    font-size: 12px;
  }
.carousel-container {
  width: 100%;
  position: relative;
}


.nav-link.active {
color:#0C0C68!important ;
font-weight:600;
}
.thumbnails {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  overflow-y: auto;
  top: 180px;
  z-index: 1;
  right: 35px;
  bottom: 30px;
}

.thumbnails img {
  margin: 10px 0;
  cursor: pointer;
  transition: transform 0.3s, border 0.3s;
  height: 83px;
}



.carousel-content {
  position: absolute;
  bottom: 4%;
  left: 20px;
  color: white;
  font-size: 24px;
  padding: 15px;
  border-radius: 5px;
  width: 68%;
  line-height: 20px;
}
.carousel-content h2 {
  font-family: "oswald";
  color: #fff;
  line-height: 42px;
  text-transform: uppercase;
}

/* Fixture */


.box-container h3{
  font-family: "oswald";
  color: #3A3A62;
font-size: 70px!important;
font-weight: 800;
margin-left: 30px;
letter-spacing: 1px;
}
.headingsection{
  width: fit-content;

}
.headingsection img{
  float: right;
  width: 40px;
  margin-top: -32px;
}
#matchbg .headingsection h4 {
margin-bottom: 6px;
margin-top: 20px;
font-size: 25px!important;
text-align: center;
}
.headingsection h4 {
  color: #20204f;
  width: fit-content;
white-space: nowrap;
  font-family: "oswald";
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 40px;
}
.text-black{
  color: #20204f!important;
}
.fixturessection {
  padding: 50px 0px 40px 30px;
  background-image: url('./34gdrt 1.svg');
  background-size: cover;
    background-repeat: no-repeat;
    background-position: right;
}
.fixturessection  .owl-item {
    height: 285px;
}
.fixturecard {
  background-color: #20204f;
  height: 265px;
  padding: 15px;
  border-radius: 8px;
  background-image: url('./abstractcard.png');
  background-size: cover;
}
.fixturecard .rightbox button {
  background: linear-gradient(187deg, rgba(255,241,87,1) 0%, rgba(227,176,39,1) 48%);
margin-left: -1px;
  border: 0px;
  color: #20204f;
  font-family: "poppins";
  font-weight: 600;
  padding: 6px 10px;
  border-radius: 8px;
  font-size: 13px;
}
.teamnamefixture
{
  color: #fff;
  text-align: center;
  white-space: nowrap;
  font-family: 'Poppins';
  margin-top: 10px;
}
.fixturecard .leftbox p {
  color: #fff;
  font-weight: 400;
  font-family: "poppins";
  font-size: 13px;
  margin-bottom: 8px;
}
.fixturecard .leftbox .p1 {
  color: #fff;
  font-weight: 400;
  font-size: 13px;
  margin-bottom: 10px;
}
.separateline {
  height: 1px;
  background-color: #ffffff77;
  width: 92%;
  margin: auto;
  display: block;
  margin-bottom: 20px;
}
.scoreteam{
  padding: 10px 30px 0px 20px;
  width: 90%;
  margin: auto;
}

.teamlogo {
    height: 78px;
    width:78px;
    background-color: #fff;
    border-radius: 200px;
    overflow: hidden; /* Ensures the image stays within the circle */
    display: flex;
    justify-content: center;
    align-items: center;
}
.teamlogo  img{
    width:100px!important;
    height: auto; /* Maintain image aspect ratio */
    object-fit: cover;
}
.teamscore{
    margin-top: 20px;
}
.teamscore h4{
    color: #fff;
    text-align: center;
    font-family: "poppins";
    font-weight: 600;
    font-size: 32px;
    margin-bottom: 10px;
}
.teamscore p{
    color: #fff;
    text-align: center;
    font-family: "poppins";
    font-size: 15px;
    margin-bottom: 0px;
    font-weight: 500;
    margin-top: 0px;
}

.footerteams{
  width: 75%!important;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;

}

.nav-link:hover{
  color: #20204F;
}
.fulltime{
  /* background: linear-gradient(180deg, rgba(255,241,87,1) 0%, rgba(227,176,39,1) 48%); */
  background-image: url('./goldenshade.png');
  background-size: cover;
  background-position: bottom;
  
    color:#20204F;
    font-size: 14px!important;
    font-family: "poppins";
    border-radius: 20px;
    padding:4px 25px!important;
    font-weight: 500;
    margin: auto;
    display: block;
    border: 0px;
    margin-top: 16px;
}
/* matchs */

#matchbg{
  padding:20px 50px;
  background-color: #191940;
  background-image: url('./blueabstarct.png');
  background-size: cover;
  background-repeat: no-repeat;
}
.matches-box{
  margin-bottom: 30px;
}
.matchhead{
  color: #fff;
    font-family: 'Oswald';
    font-size: 27px;
    margin-bottom: 20px;
}
.navbar-toggler:hover{
  border: 0px;
}
.bottom-box-match img{
width: 90px;
margin: auto;
border-radius: 100px;
}
.top-box-match{
  background:linear-gradient(87deg, rgb(237 194 62) 17%, rgb(208 151 23) 76%);
  background-size: cover;
  padding: 10px 0px 10px 18px;
}

.bottom-box-match{
  background-image: url('./pattern.png');
  padding: 18px 56px 0px 56px;
  background-color: #f5f5f5;
  background-size: cover;
  height: 180px;
}
.paramatches
{
  font-family: 'Poppins';
  text-align: center;
  font-weight: 500;
  width: 90%;
  font-size: 15px;
  line-height: 19px;
  margin-top: 10px;
}
.center-match-box {
  justify-content: center;
}
.center-match-box h4{
  font-family: 'poppins';
  text-align: center;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 5px;
  color: #20204F;
}

.center-match-box p{
  font-family: 'poppins';
  text-align: center;
  font-weight: 500;
  font-size: 13px;
  color: #000;
}

.top-box-match p{
  color: #20204F;
  font-size: 12px;
  font-family: 'poppins';
  margin-bottom: 0px;
  font-weight: 600;
}


.countdown-card {
  display: flex;
  align-items: center;
  background-color: #FFD700; /* Yellow color */
  padding:2px;
  font-family: 'poppins';
  justify-content: center;
  width: fit-content;
  margin: auto;
}

.countdown-item {
  font-size: 30px;
  color: #1F1F3E; /* Dark blue color */
  margin: 0 6px;
  font-weight: 600;
}

.separator {
  font-size: 25px;
  font-family: 'oswald';
  color: #1F1F3E; /* Dark blue color */
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
}
.news-section{
  padding: 50px 20px;
  background-color: #20204f;
}
.news-section .blogpara{
  background-color: #f4c30d;
  padding: 8px 15px;
  margin-bottom: 40px;
}
.news-section  .bloginformation img{
  border-radius: 0px!important;
}
@media screen and (max-width: 760px) {
  .news-section{
    padding: 30px 8px;
  }
  .news-section .blogpara{
    margin-bottom: 20px;
  }
  .countdown-item, .separator {
      font-size: 1.5rem;
      margin: 0 5px;

  }
  .blog-section{
    margin-top: auto!important;
  }
  .bottom-box-match{
    padding: 15px;
  }
  .hero-section{
    height: 30vh!important;
  }
  .hero-content{
    max-width: 90%!important;
  }
  .bottom-box-match{
    height: 160px!  important;
  }
  .hero-content h1{
    font-size: 20px!important;
  }
  .countdown-card {
      padding: 10px;
  }
  .sidebar-blog{
    display: none!important;
  }
}

/* matches */
/* player */

/* Ipkl.css */
.playersection {
  position: relative;
  padding: 20px;
}

.playerthumbnail {
  display: flex;
  justify-content: center;
  overflow-x: auto;
  scroll-behavior: smooth;
  background-image: url('./bluestrip.png');
  background-size: cover;

}

.playerthumbnail::-webkit-scrollbar {
  display: none; /* Hide scrollbar */
}

.item-image {
  margin: 0 10px;
  cursor: pointer;
  transition: all 0.2s ease;
  filter: grayscale(100%); /* Default grayscale for non-active items */
  background-color: #fff;
}

.item-image.active {
  filter: none; /* Remove grayscale for the active item */
  border: 2px solid red; /* Highlight the active thumbnail */
}



.arrow {
  position: absolute;
  bottom:25px;
  left: 49%;
  transform: translateX(-50%);
  display: none;
  width: 119px !important;
  height: auto !important;
}


.playercard, .item-image {
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.fade-up {
  opacity: 0;
  transform: translateY(20px);
}

.active.fade-up {
  opacity: 1;
  transform: translateY(0);
}




.item-image.active + .arrow {
  display: block; /* Show arrow when the thumbnail is active */
}


.box-container {
  position: relative;
}

.boxshadow {
  position: absolute;
  width:70%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

.playercard {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}




.item-image.active {
  border: 3px solid #F4C30D; 
  filter: none;             /* Remove grayscale filter for active thumbnail */
}

.playersection{
    background-color: #20204F;
    padding: 20px 0px 0px 0px;
    background-image: url('./playbg.png');
    background-size: cover;
    background-repeat: no-repeat;
}
.player-content {
  position: relative;
  left: 10%;
  width: 80%;
}
.player-content h5{
  font-family: "oswald";
    margin-top:40px;
    color: #fff;
    font-size: 42px;
    text-transform: capitalize;
    font-weight: 600;
}

.playerthumbnail{
    width: 100%;
    display: flex;
    overflow: scroll;
    margin-top: 30px;
    height: 150px;
}
.player-content p{
    font-family: 'poppins';
    margin-top: 20px;
    font-weight: 300;
    color: #fff;
    font-size: 14px;
    width: 80%;
}
.playerthumbnail img{
    margin-right: 36px;
    width: 100px;
    height: 100px;
    /* background-color: #fff; */
    border-radius: 60px;
    /* border: 1px solid  #fff; */
}
/* Thumbnail styles */
.item-image {
    transition: transform 0.3s ease, filter 0.3s ease;
    cursor: pointer;
  }
  
  /* Active thumbnail style */
  .item-image.active {
    /* transform: scale(1.1);  */
    filter: none; /* No grayscale */
  }
  
  /* Inactive thumbnail style */
  .item-image.inactive {
    filter: grayscale(100%); /* Full grayscale for inactive thumbnails */
  }
  



  .player-slider {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .player-carousel .player-item {
    text-align: center;
    padding: 20px;
  }
  
  .player-stats {
    display: flex;
    justify-content: space-around;
  }
  
  .thumbnail-slider {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .thumbnail-item {
    margin: 0 5px;
    cursor: pointer;
  }
  
  .thumbnail-item.active img {
    border: 2px solid #ffcc00;
  }
  
  .view-profile {
    margin-top: 10px;
    padding: 5px 15px;
    background-color: #ffcc00;
    border: none;
    cursor: pointer;
  }
  .boxstats{
    display: flex;
    margin-top: 50px;
  }
  .box1{
    background-color: #F4C30D;
    height: 100px;
    width: 150px;
    margin-right: 20px;
  }
  .box1 p{
    color: #20204F;
    background-color: #fff;
    width: 100%;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    font-family: "poppins";
    margin: 0px;
    padding: 6px;
  }
  .box1 h2{
    color: #20204F;
    width: 100%;
    font-weight: 600;
    letter-spacing: 1px;
    text-shadow: 1px 0 #ffffff, -1px 0 #ffffff, 0 1px #ffffff, 0 -1px #ffffff;
  

    text-align: center;
    font-size: 36px;
    font-weight: 700;
    font-family: "poppins";
    padding: 6px;
    line-height: 50px;
  }
  .hero-icon{
    margin: auto;
    display: block;
  }

  .box-container {
    position: relative;
  }
  
  .boxshadow {
    position: absolute;
    border-radius: 70px!important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2; /* Higher z-index to appear above the image */
    background: linear-gradient(to top, #f4c30d 6%, #f4c30d00 42%);
    width: 100%;
    margin: auto;
    display: block;
  }
  
  .playercard {
    position: relative;
    z-index: 1; /* Lower z-index to appear below the box shadow */
  }
  
  

  .viewprofile{
    margin: auto;
    display: block;
    width: 80%;
    margin-left: -10px;

  }



  .image-container {
    display: flex;
    align-items: center; /* Center the buttons vertically */
    justify-content: center; /* Center the content horizontally */
  }
  
  .prev-button, .next-button {
    background-color: #f0f0f0; /* Adjust button styles as needed */
    border: none;
    padding: 10px;
    margin: 0 10px;
    cursor: pointer;
  }
  
  .playercard {
    max-width: 100%; /* Ensure image scales responsively */
    height: auto;
  }
  


  
  

  
  .viewprofile button{
    background-color: #F4C30D;
    padding: 10px 20px;
    border: 0px;
    border-radius: 7px;
    font-family: "poppins";
display: block;
margin: 70px auto 60px auto;
    color: #20204F;
font-weight: 600;
  }
  .playerbg{
    background-color: #0C0C68;
    height: 140px;
    overflow: hidden; /* Ensures the image stays within the circle */
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .playerbg button{
    padding: 5px 30px;
    background-color: #dbac00;
    color: #20204F;
    font-family: 'poppins';
    font-weight:600;
    border-radius: 0px 0px 20px  20px ;
    position: absolute;
    border: 0px;
    font-size: 16px;
    top: 0px;
  }
  .playercard{
    width: 60%;
    margin: auto;
    display: block;
  }
  .playerbg h4{
    color: #fff;
    text-align: center;
    font-weight: 500;
    font-size: 26px;
    font-family: 'poppins';
    margin-top: 30px;
  }



.logosm{
    display: none;
}







  /* about */

  .aboutsection{
    background-image: url('./aboutbg.png');
    background-size: cover;
    background-repeat: no-repeat;
    padding: 80px  40px;
  }
  .aboutleft-content h2{
    color: #fff;
    text-transform: uppercase;
    font-family: "oswald";
    font-size: 42px;
    line-height: 50px;
    margin-bottom: 50px;
  }

  .aboutleft-content p{
    color: #fff;
    font-family: "poppins";
    width: 90%;
    justify-content: center;
    text-align: justify;
    font-size: 15px;
    line-height: 26px;
    font-weight: 400;
  }


  .miissbox img{
    background-image: url('./goldenshade.png');
    background-size: cover;
    background-position: center;
    padding: 15px;
    border-radius: 5px;
  }
  .miissbox{
    background-color: #21215163;
    padding: 30px;
    border-radius: 5px;
  }
  /* missionvision */
#mission-vision{
    background-image:url('./123.jpg')!important;
    padding: 60px 30px;
    background-size: cover;
    background: #32326D;
    background-position: center;
}
.mission-heading h4 {
    color:  #fff;
    font-family: 'oswald';
    white-space: nowrap;
    font-size: 32px;
    margin-bottom: 30px;
    font-weight: 500;
    text-transform: uppercase;
}
.mission{
    width:95%;
    margin: auto;
}

.fa-circle{
    color: #ffc107;
}

.mission-para p{
    color:  #fff;
    font-family: 'poppins';
    font-size: 15px;
    padding: 0px 12px;
    font-weight:300!important;
}


/* founder-meesage */
#founder-message{
    padding: 0px 30px;
    background-color: #20204F;
}
.founderline{
    height:4px;
    margin-top: 0px;
    width: 70px;
    background-color: #ffc107;
}
.founder-caption{
    padding:85px 0px 56px 80px;
}
.founder-image  .owl-carousel .owl-stage-outer{
  width: 100%!important;
  pointer-events: none;
}
.founder-image  .owl-carousel .owl-item img
{
  padding: 0px 10px;
}

.founder-image img{
  left: 0px;
}
.founder-caption p{
    color: #fff;

    font-family: 'poppins';
    line-height: 30px;
    font-size: 15px;
}
/* maruq */

.marqueemove {
  width: 100%;
  overflow: hidden;
  position: relative;
  height: 35px; /* Adjust height as needed */
}

.marquee {
  position: absolute;
  width: 100%;
  display: inline-block;
  white-space: nowrap;
  animation: marquee-rotate 12s linear infinite;
  /* Prevents text from being hidden while scrolling */
  will-change: transform;
}


.fa:hover{
    color: #ffc107;
    transition:1s ease-in-out;
}
.d-md-none{
  display: none;
}


/* founder-message */
.founderheading{
    margin-bottom: 30px;
}
.founderheading h4{
    color: #ffffff9f;
    font-size: 60px;
    font-family: 'poppins';
    font-weight: 700;
}
.founderheading h6{
    color: #ffffff9f;
    font-size: 16px;
    font-weight: 400;
    font-family: 'poppins';
}
.founder-left h4{
  color: #fff;
    font-family: 'Oswald';
    font-size: 38px;
    line-height: 40px;
    width: 90%;
    line-height: 42px;
    letter-spacing: 1px;
    margin-right: 20px;
}
.founder-left{
  left: 20px;
  position: relative;
}
.bg-blue1{
    background-color: #2F2F83;

  }


  #countdown{
    padding: 20px 40px;
    width: 100%;
    background-image: url('./website-banner-1440-300-04.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #ffc107;
  }

  #countdown h2{
    background: #72727217;
    padding: 15px;
    border-radius: 6px;
    width: fit-content;
    margin: 20px auto;
  }

  #countdown  h1{

color: #fff;
text-align: center;
margin-bottom: 40px;
margin-top: 20px;
font-family: 'poppins';
color: #20204f;
font-weight:600;
  }
  #countdown  h2{
    text-align: center;
    font-size: 44px;
    font-family: 'poppins';
    font-weight: 600;
  }

  /* footer  */
  #footer{
    background-color: #20204F;
    padding: 60px 20px 10px 20px;
    background-image: url('./footerdot.png');
    background-size: cover;
    background-position: center;
  }

  .footerlogo{
    width: 120px;
    margin: auto;
    display: block;

  }
  .footer-menu-link{
    margin-top: 22px;
  }
  .footer-menu-link li a{
    color: #fff;
    font-size: 16px;
  }
  .copy-right{
    padding: 12px 10px;
    margin-top: 40px;
  }

  .imagelogo{
    margin: auto;
    display: block;
  }
  .imagelogo img{
    width: 60%;
    margin: auto;
  }
  .sponsorship-bg{
    padding: 30px 0px;
    /* background-image: url('./34gdrt 1.svg'); */
    background-size: cover;
    background-repeat: no-repeat;
  }
  .footer-sponsor p{
    margin-top: 10px;
    font-size: 15px;
    font-family: 'poppins';
    font-weight: 500;
    color: #20204f;
  }
  .sponsor-heading h4{
    font-size: 32px;
    font-weight: 600;
    font-family: 'oswald';
  text-transform: uppercase;

    text-align: center;
  }
  .copy-right p{
    color: #fff;
    font-weight:400;
    margin-bottom: 0px;
    font-size: 14px;
    text-align: center;
    font-family: 'poppins';
  }
  .footer-menu-link  ul{
    display: flex;
    justify-content: center;
  }
  .footer-menu-link li{
    color: #fff;
    list-style-type: none;
    margin-left: 30px;
    font-family: 'poppins';
    white-space: nowrap;
  }














  /* team section */


  .Punjab{
    background-image: url('https://dev-ipkl.ipkl.in/files/Blue Frame.png')!important;
  }
  .UP{
    background-image: url('https://dev-ipkl.ipkl.in/files/Gray frame.png')!important;
  }

  .Rajasthan{
    background-image: url('https://dev-ipkl.ipkl.in/files/Frame 307.png')!important;
  }
  .Bengaluru {
    background-image: url('https://dev-ipkl.ipkl.in/files/Frame Red.png')!important;

  }

  .Delhi {
    background-image: url('https://dev-ipkl.ipkl.in/files/Frame Blue.png')!important;

  }
  .widthfit{
    width: fit-content;
  }
  .linecard{
    background-color: #F4C30D;
    height: 1.5px;
    width: 100%;
  }

.Mumbai{
  background-image: url('https://dev-ipkl.ipkl.in/files/Frame%20Yellow.png')!important;

}
  .Haryana{
    background-image: url('https://dev-ipkl.ipkl.in/files/Frame%20308.png')!important;
  }
  .Gujarat{
    background-image: url('https://dev-ipkl.ipkl.in/files/Gray%20frame.png')!important;
  }
.teamnewcard{
  margin-bottom: 50px;
  margin: 10px;
}
  #teamsection{
    padding: 50px 20px 30px 20px;
    background-image: url('./smokebg.png')!important;
    background: #20204F;
  }
  .team-card-bengaluru-bison {
    position: relative;
    margin-bottom: 20px;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    border-radius: 10px;
    padding:30px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    color: white;
}

.team-card-heading {
    font-size: 26px;
    font-family: 'oswald';
    margin-top: 15px;
}

.team-card-underline {
    border: 0;
    height: 2px;
    background-color: #FFD700; /* Golden color for the line */
    width: 60px;
    margin-top: 10px;
    margin-bottom: 20px;
}

.team-card-buttons {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 30px;
    margin-bottom: 10px;
}

.team-card-btn {
    padding: 7px 15px;
    width: 35%!important;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    width: fit-content;
    font-family: 'poppins';
}

.team-card-matches-btn {
    background-color: white;
    color: #001F54;
}

.team-card-matches-btn:hover {
  background-color: transparent;
  color: white;
  border: 1px solid white;
  transition:1s ease;
}

.team-card-profile-btn {
    background-color: transparent;
    color: white;
    border: 1px solid white;
}


.team-card-profile-btn:hover {
  background-color: white;
  color: #20204f;
  transition:1s ease;
  /* border: 1px solid white; */
}

.team-card-logo-corner {
    position: absolute;
    bottom: 0px;
    right: 0px;
}


.team-card-logo {
  position: relative;
  transition: transform 0.8s ease-in-out;
}

.team-card-logo {
  width: 88px;
  height: 88px;
  border-radius: 50%;
  border: 2px solid white;
  position: relative;
  top: -26px;
  left: 74px;
  z-index: 0;
}

/* Hover effect */
.team-card-bengaluru-bison:hover .team-card-logo {
  transform: translate(-20px, -20px); /* Move the logo corner up diagonally */
}

.team-card-logo {
  width: 88px;
  height: 88px;
  border-radius: 50%;
  border: 2px solid white;
  top: -26px;
  position: relative;
  left: 74px;
  z-index: 0;
}

.team-card-logo1 {
  height: 100px;
  z-index: 1;
  position: relative;
}



.image-container {
  position: relative;
}

.team-name {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent background */
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 18px;
}

.img-fluid {
  width: 100%;
  height: auto;
  display: block;
}

.view-profile {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;
  opacity: 0.8;
}

.view-profile:hover {
  opacity: 1;
}



#teamsection::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5); /* Adjust the last value for opacity */
  z-index: -1; /* Make sure the overlay is behind other content */
}
.team-card{
  margin-bottom: 50px;
  padding: 2px;
}

  /*  */
  @keyframes blink {
    0% {
      opacity: #e0b516; /* Fully visible */
    }
    50% {
      color: #20204f;
    }
    0% {
        opacity: #dbac00; /* Fully visible */
      }
  }
  
  .fa-star {
    animation: blink 3s infinite; /* 1 second duration, infinite repetitions */
  }
/* Add a blank space delay between the marquee cycles */

@keyframes marquee-rotate {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}


.aboutright img{
    animation: moveUpDown 2s ease-in-out infinite;
}
@keyframes moveUpDown {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px); /* Adjust the distance of the movement */
    }
    100% {
        transform: translateY(0);
    }
}


.Toastify  div{
  font-family: 'poppins';
  font-size: 12px;
  color: #000;
}
.quote{
  float: right;
  width: 70px;
  left: 50px!important;
  top: -26px;
  position: relative;
}
.founder-caption{
  pointer-events: none;
}

a{
  color: #fff;
  text-decoration: none;
}
.form-email{
  margin-top: 50px;
  margin-bottom: 10px;
  color: #fff;
  text-align: center;
  font-family: 'poppins';
}
.form-email1{
  margin-bottom: 0px;
  color: #fff;
  text-align: center;
  font-family: 'poppins';

}

/* coming soon */
.coming-soon{
    background-color: #ffc107;
    padding: 100px 30px;
   
}
.stadium-bg{
    background-image: url('./date-banner-02.jpg');
    height: 70vh;
    background-size: cover;
    background-repeat: no-repeat;
}
.coming-soon h4{
    font-size: 100px;
    text-align: center;
    color: #20204F;
    font-family: 'poppins';
}
.form-sponsor{
    padding: 50px 30px;
    background-color: #191940;
    background-image: url('./smokebg.png')!important;

}
.addressinfo h5{
    color: #fff;
    font-size: 33px;
    color: #20204F;
    font-family: 'poppins';
}
.form-style{
    padding: 30px;
    width: 80%;
    margin: auto;
    display: block;
}
.form-style h5{
    font-size: 32px;
    font-family: 'oswald';
    text-transform: capitalize;
    font-weight: 500;
    text-align: center;
    margin-bottom: 30px;
    color: #fff;
}
.form-control{
background-color: #fff;
color:#20204F!important;
border: 2px solid #F4C30D;
font-size: 15px;
font-weight:500;
font-family: 'poppins';
font-weight: 600;
color: #20204F!important;
padding: 15px;
width:100%;
margin-left: auto;
margin-right: auto;
margin-bottom:30px;
}
.teamscore .lightweight{
  font-weight: 300!important;
  font-size: 14px;
}
#myTab{
    background: #fff;
    width:32%;
    display: flex;
    justify-content: space-between;
    border-radius: 32px;
    padding: 9px;
    border: 3px solid #dbac00!important;
    margin: 60px auto!important;
}
.btn-primary:hover{
  background-color: #dbac00;
}
.btn-primary{
    background: linear-gradient(87deg, rgb(237 194 62) 17%, rgb(182 131 16) 76%);
    font-family: 'poppins';
    color: #20204F;
    border: 0px;
    margin: auto;
    display: block;
    padding: 10px 30px;
    font-weight: 500;
    border-radius: 30px;
}
.nav-tabs .active{
 background:linear-gradient(87deg, rgb(237 194 62) 17%, rgb(202 154 46) 76%);
 border: 0px;
 border-radius: 20px;
 color: #000!important;
 padding: 5px 30px;
}

.nav-tabs button{
    background-color: #fff!important;
 border: 0px!important;
 color: #00000082;
 border-radius: 20px;
 font-weight: 600;
 padding: 5px 30px; 
}
.nav-tabs button:hover{
  color: #20204F; 
}
.nav-tabs li{
    font-family: 'poppins';
}
.form-btn{
    width: 12%;
    padding: 10px;
    border: 0px;
    margin: 30px auto;
    display: block;
    border-radius: 10px;
    border-radius: 10px;
    font-family: 'poppins';
    background-color: #F4C30D;
    color: #20204F;
    display: block;
}

/* ipkltv */

.ipkl-tv{
  padding: 50px 0px 50px 20px;
  background-image: url('./smokebg.png')!important;
  background-size: cover;
  background-color: #20204f;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;


}

.video-container img{
  border-radius: 5px;
}
.video-container img:hover{
  filter: grayscale(70%);
  transition: 1s ease-in-out;
}




.video-container {
  position: relative;
  display: inline-block;
}

.video-thumbnail {
  width: 100%; /* Adjust as needed */
  height: auto; /* Adjust as needed */
}

.play-button {
  position: absolute;
  top: 7%;
  left: 12%;
  transform: translate(-50%, -50%);
  font-size: 50px; /* Adjust size as needed */
  color: white; /* Change color as needed */
  opacity: 0.8;
  transition: transform 0.3s ease;
}

.video-container:hover .play-button {
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
      transform: translate(-50%, -50%) scale(1);
  }
  50% {
      transform: translate(-50%, -50%) scale(1.1);
  }
  100% {
      transform: translate(-50%, -50%) scale(1);
  }
}



/* standing */

#standing{
  background-image: url('./bgstanding.png');
  padding: 50px 40px;
}
.headingstanding h4{
  color: #fff;
  font-size: 32px;
  text-align: center;
  font-family: 'Oswald';
  font-weight: 700;
  margin-bottom: 50px;
}

.custom-header th{
  background-color: #FFC107;
  border: 0px;
}
.custom-header {
  background-color: #FFC107; /* Yellow background */
  color: #000; /* Black text */
}
.bogimage{
  margin: 30px 0px;
}

.custom-table tr:nth-child(even) {
  background-color: #fff; /* White background for odd rows */
}

.custom-table tr:nth-child(odd) {
  background-color: #f2f2f2; /* Light gray background for even rows */
}
.table ul{
  list-style-type: none;
  justify-content: center;
}

.form-win {
  background-color: green;
  color: white;
  padding: 5px 10px;
  border-radius: 30px;
  margin-right: 10px;
  font-family: 'poppins';
  width: 28px;
  height: 28px;
  line-height: 15px;
  left: 0px;
}
.text-left{
  text-align: left!important;
}
.form-loss {
  background-color: red;
  color: white;
  padding: 5px 10px;
  border-radius: 30px;
  margin-right: 10px;
  font-family: 'poppins';
  width: 28px;
  height: 28px;
  line-height: 15px;
  left: 0px;
}

.form-toss {
  background-color: #aaaaaa80;
  color: white;
  padding: 5px 10px;
  border-radius: 30px;
  margin-right: 10px;
  font-family: 'poppins';
  width: 28px;
  height: 28px;
  line-height: 15px;
  left: 0px;
}
.form-win p{
  left: -3px;
  position: relative;
  font-family: 'poppins';
  top: 2px;
  font-weight: 400!important;
}

.form-toss p{
  left: 0px;
  position: relative;
  font-family: 'poppins';
  top: 2px;
  font-weight: 400!important;
}
.form-loss p{
  left: -1px;
  position: relative;
  font-family: 'poppins';
  top: 2px;
  font-weight:500!important;
}
.tablwidth{
  width: 25%;
}
.tablwidth1{
  width: 15%;
}

.form-tie {
  background-color: grey;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
}

.custom-header th{
  font-size: 14px;
  font-weight: 600;
  color: #20204F;
  font-family: 'poppins';
  text-align: center;
}

.table  td{
  font-size: 14px;
  font-weight: 500;
  text-align: center; 
    vertical-align: middle;
  color: #20204F;
  font-family: 'poppins';
  text-align: center;
  padding: 2px;
  border: 0px;
}











/* testinimial */


#testinimial .carousel-container {
  margin: 0 auto;
  padding: 20px;
}


.testimonial-card {
  border-radius: 10px;
  padding: 30px 20px;
  background-color: #fff;
  text-align: left;
  border: 1px solid #ddd;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

}
.testimonial-card .fa-star{
  margin-bottom: 30px;
  color: #dbac00;
}

#testinimial .carousel-container{
  background-color: #F4C30D;
  padding: 60px 80px  30px ;
}


#testinimial .owl-carousel .owl-item.center .item {
  opacity: 1;
  transform: scale(1.2) translateY(-10px);


}

#testinimial .owl-item {
  position: relative;
  transition: z-index 0.3s ease, opacity 0.3s ease;
}

/* Style for the center item */
#testinimial .owl-item.active.center {
  z-index: 1;
  opacity: 1;
}

/* Style for other items */
#testinimial .owl-item:not(.active.center) {
  z-index: -1;
  opacity: 0.9; /* Optional: Adjust opacity to differentiate */
  border-radius: 0px;
}
#testinimial .owl-stage-outer { margin-top: -15px; padding-top: 55px; }



#testinimial .center .testimonial-card{
  padding:40px 20px!important;
}
#testinimial .quoate-testinimial img{
  width: 50px!important;
  float: right;
  filter: grayscale(100%);
  margin-top: -30px;
  position: relative;
}


.user-testinimial{
  position: fixed;
  right: 45%;
}
.testinimail-caption{
  font-size: 38px;
  font-family: 'poppins';
  font-weight: 600;
  text-align: center;
  margin-bottom: 50px;
}

.testinimail-caption h4{
  font-size: 32px;
  font-weight: 600;
  font-family: 'poppins';
  text-align: center;
}
.user-testinimial img{
  width: 70px!important;
  align-items: center;
  margin: auto;
  display: block;
  filter: grayscale(100%);
  position: relative;
}

#testinimial  .owl-carousel .owl-item .item 
{
  text-align: center;
        margin-bottom:80px;
    opacity: .9;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;

}
.owl-carousel .owl-item{
  border-radius: 10px;
}


.testimonial-text {
  font-size: 12px;
  font-family: 'poppins';
  margin-bottom: 30px;
  text-align: left;
}

.testimonial-name {
  font-weight: 600;
  font-size: 12px;
  margin-top: 10px;
  font-family: 'poppins';
}

.testimonial-role {
  font-weight: 500;
  font-size: 10px;
  font-family: 'poppins';
}




/*   news */





/* news */

#news{
  padding: 0px;
  background-repeat: no-repeat;
  background-color: #20204f;
  background-size: cover;
  background-attachment: fixed;
}
.new-caption{
  background:linear-gradient(87deg, rgb(237 194 62) 17%, rgba(198, 147, 32, 1) 76%);
  padding: 15px;
  margin-bottom: 30px;
}

.new-caption1{
  background:linear-gradient(87deg, rgb(237 194 62) 17%, rgba(198, 147, 32, 1) 76%);
  padding: 36px 30px;
  margin-bottom: 30px;
}
.new-caption1 p{
  font-size: 18px;
  font-weight: 500;
  color: #20204F;
  font-family: 'poppins';
}
.tablwidth1{
  text-align: left!important;
}

.new-caption1 h6{
font-size: 23px;
color: #20204F;
  font-family: 'poppins';
  line-height: 33px;
  font-weight: 600;

}
.new-caption p{
  color: #20204F;
  margin-bottom: 6px;
  font-family: 'poppins';
  font-size: 12px;
  font-weight: 500;
}
.new-caption h6{
  color: #20204F;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  font-family: 'poppins';
}

/* news */





/* breadcumb */
.card {
  background-color: transparent;
}
.gallery-card{
  margin-bottom: 30px;
}

#breadcrumb-container {
  background-image: url('./banner-common.jpg'); /* Replace with your background image URL */
  background-size: cover;
  background-position: center;
  height: 233px; /* Adjust height as needed */
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Align content to the left */
  padding-left: 20px; /* Add padding to space out the content from the left */
}

.line-yellow{
  height: 2px;
  width: 100%;
  background-color: #dbac00;
}
.arrowtop {
  width: fit-content;
}
.arrowtop h1{
  white-space: nowrap;
}

.stadiumimg{
  width: 100%;
  background-color: #20204F;
}
th:nth-of-type(1) {
  width: 4%!important;
}
.breadcrumb-content h1{
  font-size: 32px;
  text-transform: uppercase;
  font-weight: 600;
}
.arrowtop img{
  float: right;
  width: 33px;
  top: -26px;
    position: relative;
}

.breadcrumb-content  p{
  margin-bottom: 2px;
}
.breadcrumb-content {
  color: white; /* Text color for contrast against the background */
  font-family: 'poppins';
  left: 2.5%;
  position: relative;
}

/* breadcumb */

/* menu icon */
.navbar-toggler-icon{
  background-image: url('./menuicon.png');
}
.navbar-toggler:focus{
  box-shadow: none!important;
}

#gallery{
  background-color: #20204f;
  padding: 50px 30px;
}
.gallery-heading h4{
  color: #fff;
  text-align: center;
  font-size: 32px;
  font-family: 'poppins';
  font-weight: 600;
  margin-bottom: 40px;
}
#gallery img{
  border-radius: 10px;
}
.navbar-nav {
  margin-left: -30px;
}
.foundernew-message{
  background-image: url('./bghome.svg');
  background-size: cover;
  background-repeat: no-repeat;
  padding: 20px 10px 20px 0px;
}
.foundernew-message .img1{
  width: 52%;
  display: block;
  margin: auto;
}
.designation{
  width: 76%;
  left: 3%;
  position: relative;
}
.designation h6{
  color: #fff;
  font-size: 32px;
  font-family: 'oswald';
  margin-top: 30px;
  font-weight: 700;
}
.cardinside .fa{
color: #fff;
font-size: 17px!important;
font-weight: 700;
}
.cardinside{
  /* background-image: url('./Rectangle 109.png'); */
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
}
.quotatnew{
  width: 44px !important;
  margin-bottom: 22px;
  float: left;
  left: -23px;
  position: relative;
}
.foundernew-message h4{
  color: #fff;
  font-size: 28px;
  text-align: center;
  font-family: 'Oswald';
  font-weight: 700;
  margin-bottom: 50px;
  text-transform: uppercase;
}
.right-para p
{
  color: #fff;
  font-family: 'poppins';
  font-size: 15px;
  width: 76%;
  font-weight:400;
  left: 3%;
  position: relative;
  margin-top: 40px;
  line-height: 26px;
  text-align: justify;
}
.designation p
{
  font-family: 'oswald';
  font-size: 18px;
  margin-top: 5px;
  font-weight:400;
  color: #fff;
} 
#matchbg .headingsection{
  margin: auto;
  display: block;
}
.lineyellowhighlight {
  height:3px;
background-color: #e3af27;
width: 100%;
margin-bottom: 40px;
}

.logomission{
  padding: 2px;
}

.ql-editor strong{
  color: #fff;
  font-family: 'oswald';
  font-size: 24px;
  margin-bottom: 20px;
  margin-top: 20px;
  font-weight: 500;
}
#founder-message .carousel-arrow{
  display:none;
}

#indiatour{
  background: linear-gradient(130deg, rgba(32,32,79,1) 0%, rgba(24,24,75,0.8184086134453782) 99%);
  padding: 30px 20px;
}

.citylist ul li{
  color: #fff;
  text-transform: uppercase;
  text-align: center;
} 
.active-city{
  color: #20204f;
  background: linear-gradient(87deg, rgb(237 194 62) 17%, rgba(198, 147, 32, 1) 76%);
}

.citylist ul li a:hover{
background: linear-gradient(87deg, rgb(237 194 62) 17%, rgba(198, 147, 32, 1) 76%);
color: #fff;
}
.citylist ul li a{
  padding: 15px 15px;

  color: #fff;
  font-family: 'oswald';
  font-size: 18px!important;
  border-bottom: 1px solid #dedede30;
}

#trophydescription{
  padding: 60px 20px;
 background-color: #20204F;

}
.trophy-left p{
  color: #fff;
  font-family: 'poppins';
  font-size: 14px;
  text-align: justify;
  width: 80%;
}
.trophyimg{
  width: 10%;
  margin: auto;
  display: block;
  transform: translateY(121px);
}
.trophysection{
  background-color: #20204F;
}

.city-tour {
  position: relative;
  height: 400px;
  display: flex;
  align-items: center;
}
.overlay-content {
  left: 3%;
}

  .overlay-content h2
  {
    font-family: 'oswald';
    font-size: 32px;
    margin-bottom: 20px;
}
.overlay-content p
{
  font-family: 'Poppins';
    font-size: 15px;
    font-weight: 400;
}

.city-tour::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background:linear-gradient(30deg, rgba(32, 32, 79, 1) 29%, rgb(24 24 75 / 21%) 99%);
  z-index: 1;
}

.overlay-content {
  position: relative;
  z-index: 2; /* To ensure content is on top of the overlay */
  color: white;
  text-align: left;
  padding: 20px;
  max-width: 36%;
}

.play-btn {
  font-size: 50px;
  color: white;
  text-decoration: none;
  position: absolute;
  right: 10%;
  top:40%;
  z-index: 2;
}

.play-btn:hover {
  color: #f8d700;
}

@media (max-width: 768px) {
  .new-caption h6{
    margin-bottom: 0px;
  }
  .nav-tabs button{
    font-size: 13px!important;
  }
  .navbar-toggler{
    border: 0px;
  }
  .new-caption1 h6{
    font-size: 16px!important;
  }
  .new-caption1{
    padding: 14px 21px;
  }
  .new-caption1 h6{
    line-height: 24px;
  }
  .new-caption1 p{
    font-size: 14px;
    margin-bottom: 0px;
  }
  .navbar-nav  li a{
    padding: 6px 20px;
    font-size: 15px !important;
    font-weight:600;
    text-transform: capitalize;
    left: 8%;
    position: relative;
    
  }

  .close-btn{
    font-family: 'Oswald';
    color: #dbac00;
  }
  .navbar-nav {
    margin-left: 10px!important;
  }
  /* gallery */

  .footer-menu-link li a{
    font-size: 14px;
  }
  #gallery{
    padding: 30px 10px;
  }
  
  .gallery-heading h4{
    font-size: 23px;
    font-weight: 500;
    margin-bottom: 30px;
  }
  /* gallery */
  .marquee {
    display: block;
  }
  .form-sponsor{
    padding: 0px;
  }
  .form-style{
    width: 100%;
    padding: 40px 10px 30px  10px;
  }
  .form-style h5{
    font-size: 22px;
    width: 80%;
    margin: auto;
    font-family: 'oswald';
    display: block;
    line-height: 32px;
  }
  
 
  .form-control{
    margin-bottom: 20px;
  }
  #myTab{
    width:86%;
  }
  .paramatches{
    font-size: 13PX;
    width: 100%;
  }
  .center-match-box h4{
    font-size: 14px;
    font-weight: 500;
  }
  .stadium-bg{
    height: 180px;
  }
  .top-box-match p{
    font-size: 9px;
    font-weight: 500;
  }

  .nav-item a{
    font-weight: 500!important;
  }
  .sponsorimg{
    width: 100%;
    margin: 0px;
  }
  .sponsorship-bg{
    padding: 30px 0px;
  }
  .sponsor-heading h4{
    font-size: 20px;
  }
}


.progress-container {
  margin-top: 15px;
}

.progress-fill {
  height: 2px;
  background-color: #ffc107;
  transition: width 0.1s linear;
}

td img{
  width: 46px!important;
}

  @media only screen and (max-width: 768px) {

    /*  testinimial*/

    td img{
      width: 20px!important;
    }
#table{
  overflow:auto;  

}
#standing{
  padding: 30px 15px;
}
.foundernew-message h4{
  font-size: 22px!important;

  font-weight: 500;
  margin-top: 20px;
}
.custom-header th{
  font-size: 10px;
  white-space: nowrap;
}
.right-para p{
  width: 100%;
  font-size: 12px;
}
.cardinside{
  padding: 20px 0px!important;
}
.news-right{
  display: none!important;
}
.right-news{
  margin-top: 15px;
}
.quotatnew{
  left: 2px;
  width: 30px!important;
}
.right-para p{
  left: 0%!important;
  margin-top: 22px!important;
  line-height: 22px!important;
}
.designation{
  left: 0%!important;
}
.designation h6{
  font-size: 18px;
  margin-top: 15px;
}
.designation{
  width: 100%;
}
.table td{
  font-size: 10px;
  padding: 8px;
  white-space: nowrap;
}
.headingstanding h4{
  font-size: 22px;
  margin-bottom: 30px;
}

.form-loss , .form-win  , .form-toss{
  width: 24px;
  height: 24px;
}
.form-win p{
  left: -3px;
  top: 0px;
}
.form-loss p{
  left: 0px;
  top: 0px;
}
.form-toss p{
  top: 0px;
  left:-1px;
}
.founder-caption p{
  font-size: 12px;
  line-height: 22px;
}
.matches-box{
  margin-right: 0px!important;
}

#countdown h2{
font-size: 18px;
}
#countdown{
  padding: 10px;
  background-position: right;
}
.coming-soon h4{
  font-size: 42px;
  font-weight: 600;
}
#countdown h1{
  font-size: 24px;
}
    #testinimial .carousel-container{
      padding: 30px 15px;
    }
    #testinimial .owl-carousel .owl-item.center .item {
      opacity: 1;
      transform: scale(1) translateY(-10px);
    }
    .user-testinimial{
      position: fixed;
      right: 40%;
    }
    .testinimail-caption h4{
      font-size: 20px;
    }
    #testinimial .owl-stage-outer{
      padding-top: 18px;
    }
    #testinimial .owl-carousel .owl-item .item{
      margin-bottom:50px;
    }
    .owl-nav,.owl-dots{
      display: none;
    }


    .user-testinimial img{
     width: 70px!important;
     height: auto!important;
    }
    #testinimial .quoate-testinimial img {
      width: 50px !important;
      float: right;
      filter: grayscale(100%);
      margin-top: -30px;
      position: relative;
      height: auto!important;
  }

  .imagelogo img {
    width: 59%;
    margin: 6px auto;

  }
    /*  testinimoal */
    .thumbnails {
      display: none;
    }
    .footerteams{
      width: 100%!important;
    }
    
    .fixturecard .leftbox p{
        font-size: 11px;
        font-weight: 400;
    }
    #matchbg{
padding: 20px 10px;
    }
    .fixturecard .leftbox .p1{
        font-size: 11px!important;
        font-weight: 500!important;


    }
    .d-sm-block {
      display: block;
    }
    .footer-menu-link  ul{
        display: flex;
        justify-content: center;
        overflow-x: scroll;
        width: 100%;
      }
    .fixturecard .rightbox button{
        font-size: 11px!important;
        font-weight: 7  00!important;
    }
    .fixturessection{
        padding: 30px 13px;
    }
    
    .d-sm-none{
        display: none;
    }
    
    .col-c2{
        width: 97%;
    }
    .socialicone ul{
        padding-left: 0px;
    }
    .preheader p{
        font-size: 10px;
    }
    .socialicone li{
        margin-right: 14px;
    }
    .carousel-content{
        position: absolute;
        bottom: 2%;
        left: 12px;
        color: white;
        font-size: 24px;
        padding: 5px;
        border-radius: 5px;
        width: 90%;
        line-height: 20px;
    }
    .carousel-content h2
    {
        line-height: 23px;
        font-size: 15px;
    }
    .matchhead{
      font-size: 20px;
    }
    .separator{
      font-size: 18px;
    }
    .footerlogo{
      width: 100px;
    }
    #footer{
      padding: 30px 10px 10px 10px;
    }
    .copy-right{
      margin-top: 15px;
    }.footer-menu-link
    {margin-top: 20px;}
    .col-c1 {
        display: none;
    }
    .teamscore h4{
        font-size: 28px;

    }
    .teamscore p{
        font-size: 12px;
    }
    .fixturecard{
        height: 250px!important;
    }
    #matchbg .headingsection h4{
      font-size: 18px!important;
      font-weight: 600!important;
      margin-top: 0px!important;
    }
    .fixturessection .owl-item{
        height: 260px;
    }
   .headingsection h4 {
        color: #20204f;
        font-family: "Poppins", sans-serif;
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 21px;
   }
   .bg-yellow{
    padding: 0px;
   }
   .mission-para p{
    font-size: 14px;
   }
   .fr
{
    flex-direction: column-reverse;
}
.aboutsection{
    padding: 45px 10px;
    background-position: center;

}
.logosm{
    width: 70px;
    padding-left: 20px;
    display: block;
}
.marque{
    display: block!important;
}
.aboutleft-content h2 {
    color: #fff;
    font-family: "poppins";
    font-size: 29px;
    line-height: 39px;
    font-weight: 600;
    margin-bottom: 20px;
    margin-top: 30px;
}
.aboutleft-content p {
    color: #fff;
    font-family: "poppins";
    width: 100%;
  text-align: left;
    /* text-align: justify; */
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
  }
  .bg-blue{
    background-color: #20204f;
  }
  .bg-blue1{
    background-color: #4949B5;

  }
 
  #mission-vision{
    padding: 40px 10px;
  }
  .mission{
    width: 100%;
    margin-bottom: 40px;
  }
  .mission-heading h4{
    color: #fff;
    font-family: 'oswald';
    font-size: 24px;
    margin-bottom: 20px;
    font-weight:500;
    text-transform: uppercase;
  }
  .founder-caption{
    padding: 30px 0px;
  }
  .miissbox img{
    display: none!important;
  }
  .miissbox{
    padding: 20px 10px;
  }
  .mission-para p{
    font-size: 14px;
    line-height: 20px;
  }
  .blog-section{
    padding: 30px 10px!important;
  }
  .arrowtop h1{
    font-size: 22px;
  }
  .arrowtop img {
    float: right;
    width: 23px;
    top: -21px;
}
  .founder-left h4{
    font-family: 'oswald';
    font-size: 24px;
    line-height: 40px;
    width: 100%;
    margin: 30px 0px 0px 0px;
  }
  .founderline{
    margin-bottom: 30px;
  }
  .founderheading h4{
    font-size: 28px;
  }
  .founderimg{
    left: 0px;
  }
  .quote {
    float: right;
    width: 57px;
    left: -8px!important;
    top: 24px;
    position: relative;
  }#founder-message{
    padding: 10px;
  }

  .foundernew-message{
    padding: 20px 12px!important;
  }
  .foundernew-message .img1{
    width: 70%!important;
    margin: auto;
  }
  .news-card-image img{
    height: auto!important;
  }
  .news-card-content{
    width: 100%!important;
  }
  .news-card-content h2{
    font-size: 22px!important;
  }
  .news-right{
    padding: 20px!important;
  }
  .news-card-content{
    top: 65%!important;
  }
  .news-card-content p{
    margin-top: 12px!important;
  }
  .founderline{
    margin-top: 5px;
  }
  .news-right h4{
    font-size: 18px!important;
  }
  .news-right .readmore{
    margin-top: 5px!important;
    margin-bottom: 6px!important;
  }
  .carousel-container img{
    height: 40vh;
  }
  .right-news-text h1{
    font-size: 16px!important;
  }
  .right-news-text .date{
    margin-bottom: 5px!important;
    font-size: 10px!important;
  }
  .hero-content h1{
    line-height: 25px!important;
  }

  .ipkl-tv{
    padding: 30px 0px 20px 15px  !important;
  }
  .news-card-content{
    left: 0%!important;
  }
  .news-card-content p{
    margin: 0;
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
    font-family: 'poppins';
    margin-top: 27px;
  }
  .news-right h4{
    line-height: 28px!important;
  }
  .player-content{
    width: 100%;
  }
  .player-content h5 {
    margin-top: 30px;
    font-size: 23px;
    text-align: center;
}
.viewprofile button{
  margin: 20px;
}
.viewprofile{
  width: 100%;
}
.arrow{
  bottom: 25px;
}
 
.image-container {
  width: 77%;
  position: relative;
  left: auto;
  margin: auto;
}

.player-content{
  left: 0%;
}
.box1 p{
  font-size: 12px;
}
.playersection{
  padding: 20px 10px;
}
#news{
  padding: 25px 10px;
}
#myTab{
  margin: 30px auto!important;
}
}







.brand-person{
  padding: 20px 20px 0px 40px ;
  background-image: url('./brandperson.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.brand-person-left {

font-family: "Oswald", system-ui;
}

.brand-person-left h5{
  font-size: 60px;
  white-space: nowrap;
  color: #ffffff3d;
  text-transform: uppercase;
}

.brand-person-left h4{
  font-size: 38px;
  color: #fff;
  margin-top: 30px;
  margin-bottom: 40px;
}

.manjeet-para p{
  color: #ffff;
  text-align: justify;
  font-family: 'poppins';
}




.card-button:hover{
  background-color: #F4C30D;
}
.breadcrumb-content p{
  text-transform: capitalize;
}


@media only screen and (max-width: 768px) {
  .breadcrumb-content p{
    font-size: 12px;
  }
  .brand-person{
    padding: 30px 10px 0px 10px ;
    background-image: url('./brandperson.png');
    background-size: cover;
    background-repeat: no-repeat;
  }
  
  .brand-person-left {
  
  font-family: "Oswald", system-ui;
  }
  
  .brand-person-left h5{
    font-size: 32px;
    white-space: nowrap;
    color: #ffffff3d;
    text-transform: uppercase;
  }
  
  .brand-person-left h4{
    font-size: 26px;
    color: #fff;
    margin-top: 20px;
    margin-bottom: 18px;
  }
  
  .manjeet-para p{
    color: #ffff;
    font-size: 15px;
  
    font-family: 'poppins';
  }

  .bluespace{
    background-color: #20204F;
    padding-top: 70px;
  }
  .manjeet-para p{
    font-size: 12px;
  }
  #teamsection{
    padding: 30px 8px;
  }
  .blog-description{
    padding: 40px 20px!important;
  }
  .blog-description h2{
    margin-top: 10px;
    font-size: 20px!important;
  }
  .blog-description h1{
    font-size: 18px !important;
    line-height: 24px !important;
  }
  .blog-description p{

    font-size: 12px!important;
  }
  #breadcrumb-container{
    height: 140px!important;
  }
  
  .headingsection img {
    width: 27px;
    margin-top: -20px;
}

}









.blog-section{
  background-color: #fff;
  padding: 30px 28px;
}
.bogimage{
  border-radius: 10px!important;
}

.sidebar-blog  ul li a{
  color: #000;
  font-family: 'oswald';
  font-weight: 500;
  font-size: 15px;
}
.sidebar-blog ul{
  padding-left: 0px;
}
.sidebarreadmore{
  font-family: 'poppins';
  font-size: 12px;
  font-weight: 600;
  color: #F3C001!important;
  margin-bottom: 15px!important;
}
.sidebar-blog  ul li {
  text-decoration: none;
  border-left:3px solid hsl(47, 91%, 50%);
  list-style-type: none;
}
.sidebarcard p{
  padding: 0px 10px;
  margin-bottom: 5px;
  color: #20204f;
}
.line-blog{
  background-color: #0C0C68;
  height: 100%;
  width: 3px;
}
.sidebar-blog

{
  border-radius: 5px;
  padding: 30px 20px;
  background-color: #fff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.blog-section{
/* background-color:#191940; */
padding: 50px 30px;
margin-top: -150px;
position: relative;
background-color: transparent;
z-index: 99999;
}

.yellowherosectionline{
  height: 5px;
  width: 90px;
  background-color: #dbac00;
  margin-top: 20px;
}

.hero-section {
  margin-top: 80px;
  position: relative;
  width: 100%;
  height: 90vh; /* Adjust height as needed */
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Align content to the left */
  overflow: hidden; /* Ensure no overflow from the image */
}

.hero-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image covers the hero section */
  z-index: 0; /* Place it below the overlay and content */
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(11deg, rgb(32 32 79), rgb(47 47 115 / 28%)); /* Adjust colors and opacity */

  z-index: 1; /* Overlay is above the image */
}

.hero-content {
  position: relative;
  z-index: 2; /* Ensure content is above the overlay */
  color: #fff; /* White text */
  max-width: 45%; /* Adjust width as needed */
  margin-left: 5%; /* Adjust to position content more to the left */
}

.hero-content h1 {
  font-size:36px; /* Adjust font size as needed */
 font-family: 'oswald';
 line-height: 45px;
}

.hero-content p {
  font-size: 14px;
  color: #F4C30D;
  font-family: 'poppins';
  font-weight: 500;
  margin-bottom: 8px;
}




.blogpara{
background-color: #fff;
padding: 10px 0px;
}

.lineyellowblog{
  width: 50px;
  background-color: #F4C30D;
  height: 4px;
  margin-top: 4px;
}
.bloginformation{
border-radius: 5px;
}
.bloginformation h2{
padding: 10px 0px;
color: #20204F;
font-size: 18px;
font-family: 'oswald';
margin-bottom: 0px;
}

.bloginformation p{
font-size: 10px;
color: #20204F;
}
.bloginformation img{
  border-radius: 5px!important;
}
.blogpara p{
font-size: 12px;
font-family: 'poppins';
color: #20204F;
font-weight: 500;
margin-bottom: 0px;
}

.bogimage{
width: 100%;
background-size: cover;
border-radius: 20px;
}

.blog-description{
padding: 30px 100px;
background-color: #20204F;
}

.blog-description p{
color: #fff;
font-family: 'poppins';
font-size: 16px;
font-weight: 300;

}

.matches-box{
  margin-right: 30px;
}
.blog-description h2{
color: #fff;
font-family: 'oswald';
margin-top: 20px;
font-size: 26px;
margin-bottom: 20px;
}


.blog-description h1{
color: #fff;
font-family: 'oswald';
margin-top: 10px;
font-size: 38px;
margin-bottom: 30px;
line-height: 48px;
}
.blog-description ul{

  padding-left: 0px!important;
}






.navbar-collapse {
  display: none;
}

.navbar-collapse.show {
  display: flex;
}

.sidebar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.sidebar-overlay.open {
  opacity: 1;
  visibility: visible;
}

.sidebar {
  position: fixed;
  top: 0;
  left: -100%;
  width: 250px;
  height: 100%;
  background-color: #fff;
  padding: 15px;
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  transition: left 0.3s ease;
}

.sidebar.slide-in {
  left: 0;
}

.sidebar.slide-out {
  left: -100%;
}

.close-btn {
  font-size: 24px;
  border: none;
  background: none;
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 15px;
}





.gallery-card {
  position: relative;
  overflow: hidden;
  border: 0px!important;
  border-radius:5px;
}
.gallery-card img {
  width: 100%;
  height: auto;
  display: block;
}
.GALLERYDETAILS {
  margin-bottom: 30px;
  border: 0px;
  border-radius: 10px ;
}
.gallery-content {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: rgb(32 32 79 / 71%);
  color: white;
  width: 100%;
  padding: 10px 20px;
  text-align: left;
}
.gallery-overlay {
  position: absolute;
  top: 0;
  left: 0;
  background:linear-gradient(87deg, rgb(237 194 62) 17%, rgba(198, 147, 32, 1) 76%);
  color: white;
  padding: 7px 14px;
  font-size: 14px;
  font-family: 'poppins';
  font-weight: 400;
  border-radius: 0px 0px 10px;
 
}
.card-title{
  font-family: 'oswald';
}
.card-text{
  font-family: 'poppins';
  font-size: 14px;
}



/* Add styles for custom navigation icons */
.custom-nav-icon {
  width: 43px; /* Adjust size of the arrow icons */
  height: 35px;
}

/* Position the navigation icons in the top-right corner */
.owl-nav {
  position: absolute;
  top: -70px; /* Adjust this value as needed */
  right: 30px; /* Adjust this value as needed */
  display: flex;
  align-items: center;
}

.owl-nav .owl-prev,
.owl-nav .owl-next {
  position: static; /* Prevent OwlCarousel from overriding position */
  margin: 0 5px; /* Space between the arrows */
}

/* Adjust these values to fit your design */
.fixturessection {
  position: relative; /* Ensures owl-nav is positioned relative to the section */
}



.modal-content button
{
    float: right;
    right: -15px;
    position: absolute;
    z-index: 99;
    height: 35px;
    width: 35px;
    border-radius: 20px;
    background: transparent;
    font-size: 25px;
    line-height: 14px;
    border: 0px;
    margin-top: -13px;
}



.modal-content button span
 
{
  top: -2px;
    position: relative;
    font-weight: 700;
    color: #20204f;
}
.modal-dialog  {
  max-width: 620px;
  margin-top: 60px;
}

/* ModalComponent.css */
.fade-in {
  opacity: 0;
  animation: fadeIn 1s forwards;
}

.fade-out {
  animation: fadeOut 1s forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}



.news-card {
  display: flex;
  align-items: center;
  border-radius: 0px;
  overflow: hidden;
  max-width: 100%;
}

.news-card-image {
  width: 40%;
  background-size: cover;
  background-position: center;
}

.news-card-content {
  padding: 20px;
  flex: 1;
}

.news-card-content h2 {
  margin: 0 0 10px;
  font-size:28px;
  font-family: 'oswald';
  text-transform: capitalize;
  color: #fff;
}

.news-card-content p {
  margin: 0;
  color: #fff;
}


.news-card {
  width: 100%;
  border-radius:0px;
  overflow: hidden;
}

.news-card-image {
  position: relative;
  width: 100%;
}

.news-card-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.news-card-gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgb(32 32 79 / 83%) 30%, rgba(0, 0, 0, 0) 100%);
  z-index: 1;
}

.news-card-content {
  position: absolute;
  top: 50%;
  width: 60%;
  left: 2%;
  transform: translateY(-50%);
  z-index: 2;
  color: #fff;
}

.news-card-content h2 {
  margin: 0 0 10px;
  font-size: 40px;
}

.news-card-content p {
  margin: 0;
  color: #fff;
  font-size:14px;
  text-transform: uppercase;
  font-family: 'poppins';
  margin-top: 50px;
}
.coloryellowstip{
  height: 2px;
  background-color: #F4C30D;
  width: 100%;
}
.news-card-image  img{
  height: 94vh;
}
.width-fit{
  width: fit-content;
}

.news-card .date p{
  color: #F4C30D;

  font-size: 13px;
  margin-bottom: 15px;
}

.news-right  h4{
  color: #fff;
  font-family: 'oswald';
  text-transform: capitalize;
  font-size: 28px;
  line-height:38px!important;
}
.news-right{
  padding: 30px 50px;
}

.news-right .date{
  color: #F4C30D;

  font-size: 13px;
  margin-bottom: 10px;
  font-family: 'poppins';
}
.news-right 
.readmore{
  color: #fff;
  font-family: 'poppins';
  text-transform: capitalize; 
  margin-bottom: 0px;
  font-size: 14px;
  margin-top: 20px;
}


.right-news {
  position: relative;
  width: 100%; /* Adjust width as needed */
  overflow: hidden;
}

.right-news img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.right-news-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background:linear-gradient(to bottom right, rgb(32 32 79), rgb(32 32 79 / 16%));
}

#news  img{
  border-radius: 0px!important;
}
.right-news-text {
  position: absolute;
  bottom:0px;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
}

.right-news-text h1{
  font-family: 'oswald';
  font-size: 22px;
  text-transform: capitalize;
}

.right-news-text .date{
  color: #F4C30D;

  font-size: 13px;
  margin-bottom: 15px;
  font-family: 'poppins';
}
.close-btn{
  display: none;
}


@media only screen and (max-width: 600px) {
  .close-btn{
    display: block;
  }
  .owl-nav {
    position: absolute;
    top: -62px;
    right: -3px;
    display: flex;
    align-items: center;
  }
  .custom-nav-icon{
    width: 35px;
    height: 35px;
  }
  .team-card-btn{
    width:35%!important;
  }
  .team-card-bengaluru-bison{
    padding-left: 20px;
  }
  .team-card-bengaluru-bison{
    padding: 10px 15px;
  }
  .teamnewcard{
    margin: 0px;
  }
  .team-card-heading{
    font-size: 22px!important;
  }
  .team-card-btn{
    font-size: 12px!important;
  }
  .team-card-logo{
    height: 70px;
    width: 70px;
  }

  


  .navbar-collapse {
    position: absolute;
    top: 0;
    left: 0;
    width: 250px; 
    background: #20204f; 
    transform: translateX(-100%);
    z-index: 1000; 
    transition: transform 0.3s ease-in-out; /* Reduced transition duration for a quicker response */
  }
  
  .navbar-collapse.show {
    transform: translateX(0); 
    height: 90vh; /* Ensure this height is what you want */
  }
  

.nav-item a,.nav-item .active{
  color: #fff!important;
  font-family: 'poppins';
  
}
.nav-item a,.nav-item{
  font-size: 22px!important;
}
.navbar-collapse.show {
  transform: translateX(0); /* Slide the menu in from the left */
  height: 80vh;
}

.navbar-nav {
  list-style: none; /* Remove default list styles */
  padding: 0; /* Remove default padding */

}
.emailcontact{
  font-size: 13px!important;
}

.navbar-nav .nav-item {
  margin: 7px 0; /* Spacing between menu items */
}

/* Style the close button */
.close-btn {
  border: none;
  color: #20204F; /* Adjust based on your theme */
  cursor: pointer;
  font-size: 15px; /* Adjust size */
  margin-top: 10px; /* Adjust margin */
}
.close-btn img{
  width: 30px;
}

/* Optional: You can add styles for the Close button on hover */















}







.playerfooter1 h5{
  FONT-FAMILY: 'Oswald';
  COLOR: #FFF;
  /* TEXT-ALIGN: CENTER; */
  FONT-SIZE: 26PX;
}


